import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faDesktop, faTachometerAlt, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import './HowItWorks.scss';
import PropTypes from 'prop-types';


class HowItWorks extends Component {
  render() {
    return (
      <div id='how-it-works-container' className='row'>
        <div className='col-10 offset-1 col-md-12 offset-md-0 text-center'>
          <div className='row'>
            <div id='how-it-works-title' className='col-10 offset-1 col-md-8 offset-md-2'>{this.props.title || 'How it works'}</div>
          </div>
          <div className='row'>
            <div className='col-10 offset-1 col-md-3 offset-md-1 my-auto'>
              <div className='row'>
                <div className='col-12'>
                  <FontAwesomeIcon className='how-it-works-icon' icon={faCogs} />
                </div>
              </div>
              <div className='row'>
                <div id='media-bias-fact-check-description' className='how-it-works-description col-12'>
                  Surfy collects basic data from&nbsp;
                  <a href='https://mediabiasfactcheck.com'>Media Bias/Fact Check</a>,
                a non-partisan organization that tracks the fact-check records and the political leanings
                of over 2800 sources.
                </div>
              </div>
            </div>
            <div className='col-12 col-md-1 px-0 my-auto'>
              <div className='row'>
                <div className='col-12 px-0'>
                  <FontAwesomeIcon className='how-it-works-arrow mx-auto' icon={faLongArrowAltRight} />
                </div>
              </div>
            </div>
            <div className='col-10 offset-1 col-md-2 offset-md-0 px-0 my-auto'>
              <div className='row'>
                <div className='col-12'>
                  <FontAwesomeIcon className='how-it-works-icon' icon={faDesktop} />
                </div>
              </div>
              <div className='row'>
                <div id='you-browse-description' className='how-it-works-description col-12 px-0'>
                  You browse the web.
                </div>
              </div>
            </div>
            <div className='col-12 col-md-1 px-0 my-auto'>
              <div className='row'>
                <div className='col-12 px-0'>
                  <FontAwesomeIcon className='how-it-works-arrow mx-auto' icon={faLongArrowAltRight} />
                </div>
              </div>
            </div>
            <div className='col-10 offset-1 col-md-3 offset-md-0 my-auto'>
              <div className='row'>
                <div className='col-12'>
                  <FontAwesomeIcon className='how-it-works-icon' icon={faTachometerAlt} />
                </div>
              </div>
              <div className='row'>
                <div className='how-it-works-description col-12'>
                  When you visit a website that Surfy recognizes, Surfy displays a color-coded banner
                  with information about the site's fact-check record and political leaning.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HowItWorks.propTypes = {
  title: PropTypes.string
}

export default HowItWorks;
