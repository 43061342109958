import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TeammateBio.scss';

class TeammateBio extends Component {
  render() {
    return (
      <div className='row text-center'>
        <div className='col-12 col-md-4 text-md-right my-auto'>
          <img width='120' height='120' className='rounded-circle' src={this.props.photo} alt={'Photo of ' + this.props.name} />
        </div>
        <div className='col-12 col-md-7 offset-md-1 text-md-left mt-3 my-md-auto'>
          <div className='row teammate-bio-header'>
            <div className='teammate-bio-name col-12'>
              <span>{this.props.name}</span>
              {/* <span className='teammate-bio-position d-none d-lg-inline ml-2 ml-md-2 ml-lg-3 ml-xl-4'>{this.props.position}</span>*/}
            </div>
            <div className='teammate-bio-position col-12'>{this.props.position}</div>
          </div>
        </div>
      </div>
    )
  }
}

TeammateBio.propTypes = {
  photo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default TeammateBio;
