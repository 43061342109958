import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/imgs/logo_original.png';
import './Navbar.scss';

function Navbar() {
  return (
    <nav id='surfy-navbar' className='navbar fixed-top navbar-expand-sm navbar-light'>
      <Link className='navbar-brand' to='/'>
        <img id='navbar-logo' src={logo} width='40' height='40' className='navbar-logo' alt='logo' />
        <span id='navbar-title' className='ml-4'>Surfy</span>
      </Link>
      <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
        <span className='navbar-toggler-icon'></span>
      </button>

      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <ul id='navbar-links' className='navbar-nav ml-auto mr-0'>
          <li id='navbar-about-btn' className='nav-item mr-sm-2 mr-md-3 mr-lg-4'>
            <Link className='nav-link' to='/about'>About</Link>
          </li>
          <li className='nav-item'>
            <a id='navbar-get-surfy-btn' className='nav-link' href='https://chrome.google.com/webstore/detail/surfy/dkedjgjmchjgkooipfpagnpmnoembahg'>
              Get&nbsp;Surfy
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
