import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/routes';
import './App.scss';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import { initializeGoogleAnalytics } from './assets/utils/GoogleAnalytics';
import { surfySummaryLong } from './assets/constants/constants';


function App() {
  initializeGoogleAnalytics();

  return (
    <Router>
      <div className="App">
        <Helmet>
          <meta name="description" content={surfySummaryLong} />
        </Helmet>
        <Navbar />
        <AppRoutes />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
