import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../components/pages/Home';
import About from '../components/pages/About';
import Donate from '../components/pages/Donate';
import Welcome from '../components/pages/Welcome';
import UnderConstruction from '../components/pages/UnderConstruction';
import ErrorPage from '../components/pages/ErrorPage.js';
import Download from '../components/pages/Download.js';


export default () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/about' component={About} />
      <Route path='/support' component={Donate} />
      <Route path='/welcome' component={Welcome} />
      <Route path='/my-ratings' component={UnderConstruction} />
      <Route path='/advanced-admin' component={UnderConstruction} />
      <Route path='/download' component={Download} />
      <Route component={ErrorPage} />
    </Switch>
  );
};


