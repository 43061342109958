import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { trackPageView } from '../../assets/utils/GoogleAnalytics';
import { patreonURL } from '../../assets/constants/constants';
import TeammateBio from '../TeammateBio';
import jeffPhoto from '../../assets/imgs/jeff.jpg';
import zachPhoto from '../../assets/imgs/zach.jpg';
import valPhoto from '../../assets/imgs/valerie.jpg';
import jordanPhoto from '../../assets/imgs/jordan.jpg';
import './About.scss';

function About() {
  trackPageView(window.location.pathname);

  return (
    <div id='about-page-container' className='container-fluid text-center text-md-left'>
      <Helmet>
        <title>About Surfy</title>
      </Helmet>
      <header className='row'>
        <div className='col-10 offset-1 col-lg-8 offset-lg-2'>
          <h1 className='page-title'>About Surfy</h1>
        </div>
      </header>
      <div className='row'>
        <div className='col-10 offset-1 col-lg-8 offset-lg-2'>
          <div className='row mt-4'>
            <h5 className='col-12'>Who built Surfy?</h5>
          </div>
          <div className='row mt-1'>
            <div className='col-12'>
              Hi, our names are Jeffrey Terry, Valerie Terry, Zach Terry, and Jordan Larsen! We created and run Surfy, a chrome extension that makes online news transparent by telling you the fact-check records and the political leanings of the websites you visit.
            </div>
          </div>
          <div className='row mt-4'>
            <h5 className='col-12'>How did Surfy start?</h5>
          </div>
          <div className='row mt-1'>
            <div className='col-12'>
              A few months ago, I (Jeff) built Surfy for myself because I wanted to know what I was reading online. After friends and family asked me if they could have it too, I decided to work out the kinks and put it up on the Chrome store for others to use. I asked Val and Jordan to join the team to help with design, and I asked Zach to join to help write the code.
            ​</div>
          </div>
          <div className='row mt-2'>
            <div className='col-12'>
              Surfy is free because we believe the Internet should be transparent for everyone. If you're interested in supporting Surfy, <Link className='inline-link' to='/support'>click here</Link> to learn more or feel free to visit <a className='inline-link' href={patreonURL}>our Patreon page</a>.
            ​</div>
          </div>
        </div>
      </div>
      <header id='who-we-are-header' className='row'>
        <div className='col-10 offset-1 col-lg-8 offset-lg-2'>
          <h1 className='page-title'>The Team</h1>
        </div>
      </header>
      <div className='row mt-1'>
        <div className='col-10 offset-1'>
          <div className='row mt-2'>
            <div className='col-12 mt-4 col-sm-6'>
              <TeammateBio photo={jeffPhoto} name='Jeffrey Terry' position='Chief Developer' description='' />
            ​</div>
            <div className='col-12 mt-4 col-sm-6'>
              <TeammateBio photo={zachPhoto} name='Zach Terry' position='Developer' description='' />
            ​</div>
            <div className='col-12 mt-4 col-sm-6'>
              <TeammateBio photo={valPhoto} name='Valerie Terry' position='Chief Designer' description='' />
            ​</div>
            <div className='col-12 mt-4 col-sm-6'>
              <TeammateBio photo={jordanPhoto} name='Jordan Larsen' position='Designer' description='' />
            ​</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
