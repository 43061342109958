import React from 'react';
import { Helmet } from 'react-helmet';
import { trackPageView } from '../../assets/utils/GoogleAnalytics';
import { surfySummary } from '../../assets/constants/constants';
import ClickableImage from '../ClickableImage.js';
import examplePhotoGood from '../../assets/imgs/examples/GoodExample.png';
import examplePhotoMixed from '../../assets/imgs/examples/MixedExample.png';
import examplePhotoSatire from '../../assets/imgs/examples/SatireExample.png';
import examplePhotoFake from '../../assets/imgs/examples/FakeNewsExample.png';
import HowItWorks from '../HowItWorks';
import PrivacyPolicy from '../PrivacyPolicy';
import './Home.scss';

function Home() {
  trackPageView(window.location.pathname);

  return (
    <div id='landing-page-container' className='container-fluid'>
      <Helmet>
        <title>Get Surfy</title>
      </Helmet>
      <div className='row'>
        <div className='col-11 offset-1 col-lg-10 offset-lg-2'>
          <span id='intro-text-small'>When you read news on the web</span>
        </div>
      </div>
      <div className='row'>
        <div className='col-11 offset-1 col-lg-10 offset-lg-2'>
          <strong id='intro-text-large'>Know what you're reading.</strong>
        </div>
      </div>
      <div id='example-photo-row' className='row'>
        <div className='col-12 col-md-10 offset-md-1'>
          <div className='row'>
            <div className='col-10 offset-1 col-md-6 offset-md-0 col-lg-4 example-photo-box mt-4'>
              <ClickableImage key='example-photo-good' uid='example-photo-good' src={examplePhotoGood} alt='Surfy on a good news site' />
            </div>
            <div className='col-10 offset-1 col-md-6 offset-md-0 col-lg-4 example-photo-box mt-4'>
              <ClickableImage key='example-photo-mixed' uid='example-photo-mixed' src={examplePhotoMixed} alt='Surfy on a so-so news site' />
            </div>
            <div className='d-none d-md-block col-md-6 offset-md-0 d-lg-none example-photo-box mt-4'>
              <ClickableImage key='example-photo-satire' uid='example-photo-satire' src={examplePhotoSatire} alt='Surfy on a satirical news site' />
            </div>
            <div className='col-10 offset-1 col-md-6 offset-md-0 col-lg-4 example-photo-box mt-4'>
              <ClickableImage key='example-photo-fake' uid='example-photo-fake' src={examplePhotoFake} alt='Surfy on a fake news site' />
            </div>
          </div>
        </div>
      </div>
      <div id='summary-text-row' className='row'>
        <div className='col-10 offset-1 col-md-10 offset-md-1 text-center'>
          <span id='summary-text'>{ surfySummary }</span>
        </div>
      </div>
      <HowItWorks />
      <PrivacyPolicy />
    </div>
  );
}

export default Home;
