import ReactGA from 'react-ga';


export const initializeGoogleAnalytics = function () {
    // Only track on production (not localhost)
    if (window.location.host === 'getsurfy.org') {
        ReactGA.initialize('UA-144852662-2');
    }
};

export const trackPageView = function (pagePath) {
    // Only track on production (not localhost)
    if (window.location.host === 'getsurfy.org') {
        ReactGA.pageview(pagePath);
    }
};
