import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ColorCode.scss';

class ColorCode extends Component {
  render() {
    return (
      <div className='row mt-3'>
        <div className={'col-4 offset-sm-2 color-code-color ' + this.props.colorClass}>
          <div className='surfy-badge'>
            Surfy
          </div>
        </div>
        <div className={'col-8 col-sm-6 pl-3 text-left color-code-meaning '}>
          {this.props.meaning}
        </div>
      </div>
    )
  }
}

ColorCode.propTypes = {
  colorClass: PropTypes.string.isRequired,
  meaning: PropTypes.string.isRequired
}

export default ColorCode;
