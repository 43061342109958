import React from 'react';
import { Helmet } from 'react-helmet';
import { trackPageView } from '../../assets/utils/GoogleAnalytics';
import { patreonURL } from '../../assets/constants/constants';
import './Donate.scss';

function Donate() {
  trackPageView(window.location.pathname);

  return (
    <div id='donate-page-container' className='container-fluid'>
      <Helmet>
        <title>Support Surfy</title>
      </Helmet>
      <header className='row'>
        <div className='col-11 offset-1 col-lg-10 offset-lg-2'>
          <h1 className='page-title'>Support Surfy</h1>
        </div>
      </header>
      <div className='row'>
        <div className='col-10 offset-1 col-lg-8 offset-lg-2'>
          <div className='row mt-4'>
            <h5 className='col-12'>How can I support Surfy?</h5>
          </div>
          <div className='row mt-1'>
            <div className='col-12'>
              You can support Surfy through <a className='inline-link' href={patreonURL}>our Patreon page</a>! Patreon is an independent online platform that allows people to support creators through monthly donations of $1-$5.
            </div>
          </div>
          <div className='row mt-4'>
            <h5 className='col-12'>What​ does your support mean?</h5>
          </div>
          <div className='row mt-1'>
            <div className='col-12'>
              Surfy is developed and run solely by volunteers. Right now, I (Jeff) pay for Surfy’s server costs out of my own pocket. While costs are currently low, once the crowdsourcing feature starts seeing more use, I expect they will rise. Any support you give will keep Surfy free!
            ​</div>
          </div>
          <div className='row mt-2'>
            <div className='col-12'>
              Your support will also help us bring new features to Surfy. Since we all work full-time, our time is limited, but our goal is to bring Surfy to Firefox and Safari within the next year. We also want to add new features in the coming months like a click-bait filter, and we also want improve the functionality of existing features like crowdsourcing. With your support, we can dedicate more time to development, which will help us make Surfy better for all!
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12'>
              More than anything, your support tells us that what we're doing matters, encouraging us to work harder to improve Surfy. Happy surfing!
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-12 text-center'>
              <a id='support-surfy-patreon-btn' className='btn' href={patreonURL} role='button'>Support Surfy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donate;
