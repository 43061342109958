import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { trackPageView } from '../../assets/utils/GoogleAnalytics';
import './Download.scss';

function Download() {
  trackPageView(window.location.pathname);

  return (
    <div id='download-page-container' className='container-fluid'>
      <Helmet>
        <title>Get Surfy</title>
      </Helmet>
      <div className='row text-center'>
        <div className='col-12'>
          <div className='h1'>
            Surfy is currently undergoing limited Beta testing.
          </div>
        </div>
      </div>
      <div className='row text-center'>
        <div className='col-12'>
          <div className='h1'>
            Check back soon for more info!
          </div>
        </div>
      </div>
      <div className='row text-center'>
        <div className='col-12'>
          <div className='huge-icon'>
            <FontAwesomeIcon icon={faFlask} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
