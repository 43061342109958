import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { trackPageView } from '../../assets/utils/GoogleAnalytics';
import { patreonURL } from '../../assets/constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import ColorCode from '../ColorCode.js';
import ClickableImage from '../ClickableImage.js';
import HowItWorks from '../HowItWorks';
import PrivacyPolicy from '../PrivacyPolicy';
import './Welcome.scss';

// Load images //
import minimizeSurfy from '../../assets/imgs/welcome/MinimizeBanner.png';
import surfyMinimized from '../../assets/imgs/welcome/BannerMinimized.png';
import pinSurfyToTheBottom from '../../assets/imgs/welcome/PinBannerToBottom.png';
import surfyPinnedToTheBottom from '../../assets/imgs/welcome/BannerPinnedToBottom.png';
import addRating from '../../assets/imgs/welcome/AddARating.png';
import flagRating from '../../assets/imgs/welcome/FlagARating.png';
import learnMore from '../../assets/imgs/welcome/LearnMore.png';
import learningMore from '../../assets/imgs/welcome/LearningMore.png';
import addRatingViaPopup from '../../assets/imgs/welcome/AddRatingViaPopup.png';
import customizeSurfyViaPopup from '../../assets/imgs/welcome/CustomizeSurfyViaPopup.png';
import participateInCrowdsourcing from '../../assets/imgs/welcome/ParticipateInCrowdsourcing.png';


function Welcome() {
  trackPageView(window.location.pathname);
    
  return (
    <div id='welcome-page-container' className='container-fluid'>
      <Helmet>
        <title>Welcome to Surfy</title>
      </Helmet>
      <header className='row'>
        <div className='col-10 offset-1 col-lg-8 offset-lg-2'>
          <h1 className='page-title'>Thank you for installing Surfy!</h1>
        </div>
        <div className='col-10 offset-1 col-lg-8 offset-lg-2'>
          <h1 className='page-description ml-5 mt-3'>
            We hope you like it
            <FontAwesomeIcon className='ml-2 smile-icon small' icon={faSmile} />
          </h1>
        </div>
      </header>
      <div className='row mt-5'>
        <div className='col-10 offset-1 col-lg-8 offset-lg-2'>
          <div className='row mt-2'>
            <div className='col-12 h4 ml-1'>To get started, check out some of Surfy's awesome features below</div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 h5 ml-1'>
              If you're interested in supporting Surfy, <Link className='inline-link' to='/support'>click here</Link> to learn more or feel free to visit <a className='inline-link' href={patreonURL}>our Patreon page</a>.
            </div>
          </div>
        </div>
      </div>

      <div id='how-to-photo-row' className='row'>
        <div className='col-12 col-md-10 offset-md-1'>
          <hr className='mt-5' />
          <div className='row mt-5'>
            <div className='col-10 offset-1 px-4'>
              <div className='row text-center'>
                <div className='col-11'>
                  <div className='h4'>
                    The color of the banner tells you what you're reading.
                  </div>
                </div>
              </div>
              <ColorCode colorClass='color-code-very-good' meaning='Very Good Fact-Check Record' />
              <ColorCode colorClass='color-code-good' meaning='Good Fact-Check Record' />
              <ColorCode colorClass='color-code-mixed' meaning='Mixed Fact-Check Record' />
              <ColorCode colorClass='color-code-poor' meaning='Poor Fact-Check Record' />
              <ColorCode colorClass='color-code-satire' meaning='Satire' />
              <ColorCode colorClass='color-code-fake' meaning='Fake News Site' />
              <ColorCode colorClass='color-code-conspiracy' meaning='Conspiracy / Pseudoscience Site' />
            </div>
          </div>
          <hr className='mt-5' />
          <div className='row mt-5'>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box px-4'>
              <ClickableImage key='how-to-photo-minimize' uid='how-to-photo-minimize' src={minimizeSurfy} alt='How to minimize Surfy' header='Click "&mdash;" to minimize the banner' />
            </div>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box mt-4 mt-lg-0 px-4'>
              <ClickableImage key='how-to-photo-minimized' uid='how-to-photo-minimized' src={surfyMinimized} alt='Surfy minimized' header='Now the banner looks like this' />
            </div>
          </div>
          <hr className='mt-5' />
          <div className='row mt-5'>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box px-4'>
              <ClickableImage key='how-to-photo-pin-to-bottom' uid='how-to-photo-pin-to-bottom' src={pinSurfyToTheBottom} alt='How to pin Surfy to the bottom of the browser' header='Click the arrow to move the banner' />
            </div>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box mt-4 mt-lg-0 px-4'>
              <ClickableImage key='how-to-photo-pinned-to-bottom' uid='how-to-photo-pinned-to-bottom' src={surfyPinnedToTheBottom} alt='Surfy pinned to the bottom of the browser' header='Now the banner looks like this' />
            </div>
          </div>
          <hr className='mt-5' />
          <div className='row mt-5'>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box px-4'>
              <ClickableImage key='how-to-photo-add-a-rating' uid='how-to-photo-add-a-rating' src={addRating} alt='Add a rating' header='Add your own ratings' />
            </div>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box mt-4 mt-lg-0 px-4'>
              <ClickableImage key='how-to-photo-flag-a-rating' uid='how-to-photo-flag-a-rating' src={flagRating} alt='Flag an inaccurate rating' header='Flag inaccurate ratings' />
            </div>
          </div>
          <hr className='mt-5' />
          <div className='row mt-5'>
            <div className='col-10 offset-1 col-md-8 offset-md-2 how-to-photo-box'>
              <ClickableImage key='how-to-photo-crowdsource' uid='how-to-photo-crowdsource' src={participateInCrowdsourcing} alt='See crowdsourced ratings' header='Your ratings show up too!' />
            </div>
          </div>
          <hr className='mt-5' />
          <div className='row mt-5'>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box px-4'>
              <ClickableImage key='how-to-photo-learn-more' uid='how-to-photo-learn-more' src={learnMore} alt='Learn more about a rating' header='Click the link in the corner to learn more about a rating' />
            </div>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box mt-4 mt-lg-0 px-4'>
              <ClickableImage key='how-to-photo-learning-more' uid='how-to-photo-learning-more' src={learningMore} alt='Visit mediabiasfactcheck.com for the complete score of a website' header='A complete explanation of the rating shows up' />
            </div>
          </div>
          <hr className='mt-5' />
          <div className='row mt-5'>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box px-4'>
              <ClickableImage key='how-to-photo-add-rating-via-popup' uid='how-to-photo-add-rating-via-popup' src={addRatingViaPopup} alt='Add a rating using the popup page' header='Use the popup to rate any site' />
            </div>
            <div className='col-10 offset-1 col-lg-6 offset-lg-0 how-to-photo-box mt-4 mt-lg-0 px-4'>
              <ClickableImage key='how-to-photo-customize-surfy' uid='how-to-photo-customize-surfy' src={customizeSurfyViaPopup} alt='Customize Surfy using the popup page' header='Use the popup to customize Surfy' />
            </div>
          </div>
        </div>
      </div>
      <HowItWorks title='How Surfy works' />
      <PrivacyPolicy />
    </div>
  );
}

export default Welcome;
