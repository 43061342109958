import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ClickableImage.scss';

class ClickableImage extends Component {
  render() {
    return (
      <React.Fragment>
        <div data-toggle='modal' data-target={'#' + this.props.uid} className='text-center'>
          <div className='clickable-image-header h4 mb-4'>{this.props.header}</div>
          <img className='img-fluid clickable-image shadow px-0' src={this.props.src} alt={this.props.alt} />
        </div>

        <div className='modal fade' id={this.props.uid} tabIndex='-1' role='dialog' aria-labelledby='exampleModalLabel' aria-hidden='true'>
          <div className='modal-dialog modal-lg' role='document'>
            <div className='modal-content'>
              <div className='modal-header clickable-image-modal-header'>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body clickable-image-modal-body'>
                <img className='img-fluid rounded' src={this.props.src} alt={this.props.alt} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

ClickableImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  header: PropTypes.string
}

export default ClickableImage;
