import React, { Component } from 'react';
import './PrivacyPolicy.scss';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div id='privacy-policy-container' className='row'>
        <div id='privacy' className='col-10 offset-1 col-md-10 offset-md-1 text-center'>
          <div className='row'>
            <div id='privacy-policy-title' className='col-10 offset-1'>
              Privacy Policy
            </div>
          </div>
          <div className='row mt-5'>
            <div id='privacy-policy-description' className='col-10 offset-1'>
              Surfy's goal is to make the Internet more transparent and useful. In line with that goal, Surfy doesn't track your behavior and it doesn't store your personal information. In fact, the only time the browser extension communicates with Surfy's server at all is when you rate websites in support of Surfy's crowdsourcing feature. So happy surfing!
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicy;
